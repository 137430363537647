import { css, cx } from '@emotion/css';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { Copyright } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import Link from 'next/link';
import { Fragment } from 'react';

export default function FooterBottom() {
  const { t } = useTranslation();

  const links = [
    {
      href: '/terms-of-service',
      label: t('terms_of_service'),
    },
    {
      href: '/privacy-policy',
      label: t('privacy_policy'),
    },
    {
      href: '/refund-policy',
      label: t('refund_policy'),
    },
  ];

  return (
    <Flex column align="center" justify="center" className={styled.root}>
      <Flex
        align="center"
        justify="center"
        className={cx(styled.policyContainer, 'sm-bold-text')}
      >
        {links.map(({ href, label }, idx) => {
          const isShowDivider = idx < links.length - 1;
          return (
            <Fragment key={idx}>
              <Link href={href} prefetch={false}>
                {label}
              </Link>
              {isShowDivider && <div className={styled.divider}>|</div>}
            </Fragment>
          );
        })}
        <Flex
          align="center"
          justify="center"
          className={cx(styled.copyrightContainer, 'sm-text')}
        >
          <Copyright fill="var(--neutral-800)" width={12} height={12} />
          <Text>
            {t('footer.copyright_content', {
              year: new Date().getFullYear(),
            })}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

const styled = {
  root: css`
    border-top: 1px solid var(--neutral-200);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  `,
  divider: css`
    margin: 0 0.375rem;
    color: var(--neutral-200);
    font-weight: var(--regular-font-weight);
  `,
  policyContainer: css`
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  `,
  copyrightContainer: css`
    > :first-child {
      margin-right: 0.25rem;
    }
  `,
};
