import { css } from '@emotion/css';
import { useGetFooterMenu } from '@hooks';
import { useTranslation } from '@topremit/shared-web/hooks';

import FooterMenuItem from './FooterMenuItem';
import FooterMenuList from './FooterMenuList';

export default function LicensedByMenu() {
  const { t } = useTranslation();
  const { licensedBy } = useGetFooterMenu();

  return (
    <FooterMenuList
      title={t('footer.media.licensed')}
      type="row"
      className={styled.root}
    >
      {licensedBy.map(({ icon, label, url, externalLink }, idx) => (
        <FooterMenuItem
          key={idx}
          leftIcon={icon}
          href={url}
          externalLink={externalLink}
        >
          {label}
        </FooterMenuItem>
      ))}
    </FooterMenuList>
  );
}

const styled = {
  root: css`
    > div {
      & :not(:last-child) {
        margin-right: 2.5rem;
      }
    }
  `,
};
