import { css, cx } from '@emotion/css';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { ReactNode } from 'react';

interface IFooterMenuListProps {
  title: string;
  className?: string;
  children: ReactNode;
  type?: 'row' | 'column';
}

export default function FooterMenuList({
  title,
  type = 'column',
  className,
  children,
}: IFooterMenuListProps) {
  const column = type === 'column';
  return (
    <div className={className}>
      <Text className={styled.title}>{title}</Text>
      <Flex column={column} className={cx('sm-text', styled.list(column))}>
        {children}
      </Flex>
    </div>
  );
}

const styled = {
  title: css`
    padding: 0.25rem 0;
    margin-bottom: 0.5rem;
    font-weight: var(--bold-font-weight);
  `,
  list: (column: boolean) => css`
    justify-content: ${column ? 'unset' : 'space-between'};
    > :not(:last-child) {
      margin-bottom: ${column ? '0.5rem' : '0'};
      margin-right: ${column ? '0' : '0.5rem'};
    }
  `,
};
