import { css } from '@emotion/css';
import { Container } from '@topremit/shared-web';

import FooterBody from './FooterBody';
import FooterBottom from './FooterBottom';
import FooterTop from './FooterTop';

function Footer() {
  return (
    <footer id="main-footer" className={styled.footer}>
      <Container className={styled.container}>
        <FooterTop />
        <FooterBody />
        <FooterBottom />
      </Container>
    </footer>
  );
}

const styled = {
  footer: css`
    margin-top: auto;
    position: relative;
    background-color: var(--neutral-100);
    display: flex;
    flex-direction: column;
    * {
      color: var(--neutral-800);
    }
    a {
      width: fit-content;
      transition: text-decoration ease 0.2s;
      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  `,
  container: css`
    padding-top: 2.5rem !important;
  `,
};

export default Footer;
