import { useGetFooterMenu } from '@hooks';
import { useTranslation } from '@topremit/shared-web/hooks';

import FooterMenuItem from './FooterMenuItem';
import FooterMenuList from './FooterMenuList';

export default function ResourcesMenu() {
  const { t } = useTranslation();
  const { resources } = useGetFooterMenu();

  return (
    <FooterMenuList title={t('footer.menu.menu_3_title')}>
      {resources.map(({ icon, label, url }, idx) => (
        <FooterMenuItem key={idx} leftIcon={icon} href={url}>
          {label}
        </FooterMenuItem>
      ))}
    </FooterMenuList>
  );
}
