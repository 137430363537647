import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex } from '@topremit/shared-web/components/elements';
import { Logo } from '@topremit/shared-web/components/shapes';
import { useMediaQuery } from '@topremit/shared-web/hooks';

export default function FooterTop() {
  const isTabletSize = useMediaQuery(`(max-width: ${screenSize.desktopSm}px)`);

  return (
    <Flex className={styled.root}>
      <Logo width={isTabletSize ? 140 : 185} height={isTabletSize ? 24 : 32} />
    </Flex>
  );
}

const styled = {
  root: css`
    margin-bottom: 2.5rem;
  `,
};
