import { useAnalytics, useGetFooterMenu } from '@hooks';
import { useTranslation } from '@topremit/shared-web/hooks';
import { AnalyticPagesNames } from '@topremit/shared-web/typings/analytics.model';
import { EFoundUsSocialMedia } from '@topremit/shared-web/typings/profile.model';

import FooterMenuItem from './FooterMenuItem';
import FooterMenuList from './FooterMenuList';

export default function ConnectWithUsMenu() {
  const { t } = useTranslation();
  const { connectWithUs } = useGetFooterMenu();

  const {
    analyticsPersonal: { tracksPersonal },
    analyticsBusiness: { tracksBusiness },
  } = useAnalytics();

  function handleClickSocialMedia(name: EFoundUsSocialMedia) {
    // TODO: Need business
    tracksPersonal.foundUsSocialMedia({
      source: AnalyticPagesNames.FOOTER,
      socmed: name,
    });
    tracksBusiness.foundUsSocialMedia({
      source: AnalyticPagesNames.FOOTER,
      socmed: name,
    });
  }

  return (
    <FooterMenuList title={t('footer.media.social_media')} type="row">
      {connectWithUs.map(
        ({ icon, label, url, externalLink, analytic }, idx) => (
          <FooterMenuItem
            key={idx}
            leftIcon={icon}
            href={url}
            externalLink={externalLink}
            analytic={analytic}
            onClick={handleClickSocialMedia}
          >
            {label}
          </FooterMenuItem>
        ),
      )}
    </FooterMenuList>
  );
}
