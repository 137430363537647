import { css } from '@emotion/css';
import { Flex } from '@topremit/shared-web/components/elements';
import {
  Appstore,
  ClockFill,
  Facebook,
  Instagram,
  KurosimLogo,
  LinkedIn,
  MailFill,
  Pinpoint,
  PlayStore,
  Tiktok,
  TopvalasLogo,
  Twitter,
  WhatsappOutline,
  Youtube,
} from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { EFoundUsSocialMedia } from '@topremit/shared-web/typings/profile.model';
import getConfig from 'next/config';
import Image from 'next/image';
import { ReactNode } from 'react';

interface Return {
  icon?: ReactNode;
  label: ReactNode;
  url?: string;
  externalLink?: boolean;
  prefetch?: boolean;
  analytic?: EFoundUsSocialMedia;
}

type Menu =
  | 'contactUs'
  | 'operationalHours'
  | 'address'
  | 'alsoByTopremit'
  | 'products'
  | 'resources'
  | 'company'
  | 'downloadApp'
  | 'connectWithUs'
  | 'licensedBy';

type FooterMenu = Record<Menu, Return[]>;

const neutral800 = 'var(--neutral-800)';
const topremitAsiaAddress = 'https://maps.app.goo.gl/mBChLC3pT9TJQKtEA';
const { publicRuntimeConfig } = getConfig();

const folderPath = publicRuntimeConfig?.staticFolder
  ? publicRuntimeConfig?.staticFolder
  : '';

export function useGetFooterMenu(): FooterMenu {
  const { t, lang } = useTranslation();

  const isBusiness =
    typeof window !== 'undefined' && window.location.href.includes('business');

  const contactUs = isBusiness
    ? [
        {
          icon: <MailFill fill={neutral800} />,
          label: 'sales@topremit.com',
          url: 'mailto:sales@topremit.com',
          externalLink: true,
        },
      ]
    : [
        {
          icon: <WhatsappOutline fill={neutral800} width={20} height={20} />,
          label: '+62 823-6196-0569',
          url: 'https://wa.me/6282361960569',
          externalLink: true,
        },
        {
          icon: <MailFill fill={neutral800} />,
          label: 'cs@topremit.com',
          url: 'mailto:cs@topremit.com',
          externalLink: true,
        },
      ];

  const operationalHours = [
    {
      icon: <ClockFill fill={neutral800} />,
      label: t('footer.help.operational_hours.time'),
    },
  ];

  const address = [
    {
      icon: <Pinpoint fill={neutral800} />,
      label: t('footer.help.address.location'),
      url: topremitAsiaAddress,
      externalLink: true,
    },
  ];

  const alsoByTopremit = [
    {
      label: <TopvalasLogo />,
      url: `https://www.topvalas.com/${lang}`,
      externalLink: true,
    },
    {
      label: <KurosimLogo />,
      url: `https://www.kurosim.com/${lang}`,
      externalLink: true,
    },
  ];

  const products = [
    {
      label: t('international_transfer'),
      url: '/',
    },
    {
      label: t('local_transfer'),
      url: '/local-transfer',
    },
    {
      label: t('topremit_business'),
      url: '/business',
    },
    {
      label: t('mobile_topup'),
      url: '/mobile-top-up',
    },
    {
      label: t('swift'),
      url: `/swift-transfer`,
    },
  ];

  const resources = [
    {
      label: t('check_available_countries'),
      url: '/send-money',
    },
    {
      label: t('track_transaction'),
      url: '/track',
    },
    {
      label: t('earn_airasia_points'),
      url: '/loyalty/airasia',
    },
    {
      label: t('swift_code_checker'),
      url: '/swift-code',
    },
    {
      label: t('cbm_calculator'),
      url: '/cbm-calculator',
    },
    {
      label: t('blog'),
      url: 'https://blog.topremit.com',
    },
    {
      label: t('faq'),
      url: `https://help.topremit.com/${lang}/support/home`,
    },
  ];

  const company = [
    {
      label: t('about_us'),
      url: '/about-us',
    },
    {
      label: t('career'),
      url: '/career',
    },
    {
      label: t('affiliate_program'),
      url: '/become-affiliate',
    },
    {
      label: t('partnership'),
      url: '/partnership',
    },
  ];

  const downloadApp = [
    {
      label: <PlayStore />,
      url: 'https://topremit.onelink.me/9tlR/821f56ae',
      externalLink: true,
    },
    {
      label: <Appstore />,
      url: 'https://link.topremit.com/9tlR/f3bf1ac8',
      externalLink: true,
    },
  ];

  const connectWithUs = [
    {
      name: 'instagram',
      label: (
        <Flex className={styled.socmedIcon}>
          <Instagram fill={neutral800} width={24} height={24} />
        </Flex>
      ),
      url: 'https://www.instagram.com/topremit',
      analytic: EFoundUsSocialMedia.INSTAGRAM,
      externalLink: true,
      prefetch: false,
    },
    {
      name: 'linkedin',
      label: (
        <Flex className={styled.socmedIcon}>
          <LinkedIn fill={neutral800} width={24} height={24} />
        </Flex>
      ),
      url: 'https://www.linkedin.com/company/top-remit',
      analytic: EFoundUsSocialMedia.LINKEDIN,
      externalLink: true,
      prefetch: false,
    },
    {
      name: 'x',
      label: (
        <Flex className={styled.socmedIcon}>
          <Twitter fill={neutral800} width={24} height={24} />
        </Flex>
      ),
      url: 'https://x.com/topremit',
      analytic: EFoundUsSocialMedia.X,
      externalLink: true,
      prefetch: false,
    },
    {
      name: 'facebook',
      label: (
        <Flex className={styled.socmedIcon}>
          <Facebook fill={neutral800} width={24} height={24} />
        </Flex>
      ),
      url: 'https://www.facebook.com/topremit',
      analytic: EFoundUsSocialMedia.FACEBOOK,
      externalLink: true,
      prefetch: false,
    },
    {
      name: 'tiktok',
      label: (
        <Flex className={styled.socmedIcon}>
          <Tiktok fill={neutral800} width={24} height={24} />
        </Flex>
      ),
      url: 'https://www.tiktok.com/@topremit',
      analytic: EFoundUsSocialMedia.TIKTOK,
      externalLink: true,
      prefetch: false,
    },
    {
      name: 'youtube',
      label: (
        <Flex className={styled.socmedIcon}>
          <Youtube fill={neutral800} width={24} height={24} />
        </Flex>
      ),
      url: 'https://www.youtube.com/@topremit.official',
      analytic: EFoundUsSocialMedia.YOUTUBE,
      externalLink: true,
      prefetch: false,
    },
  ];

  const licensedBy = [
    {
      label: (
        <Flex data-nosnippet>
          <Image
            src={`${folderPath}/images/png/bi-license-${lang}.png`}
            width={136}
            height={30}
            alt={t('image_alt.licensed_by.bi')}
          />
        </Flex>
      ),
      url: 'https://www.bi.go.id/id/qrcode/validate.aspx?tipe=PTD&id=0010.001/0001',
      externalLink: true,
    },
    {
      label: (
        <Flex data-nosnippet>
          <Image
            src={`${folderPath}/images/png/pse-kominfo.png`}
            width={56}
            height={30}
            alt={t('image_alt.licensed_by.pse')}
          />
        </Flex>
      ),
      url: 'https://pse.kominfo.go.id/tdpse-detail/14106',
      externalLink: true,
    },
  ];

  return {
    contactUs,
    operationalHours,
    address,
    alsoByTopremit,
    products,
    resources,
    company,
    downloadApp,
    connectWithUs,
    licensedBy,
  };
}

const styled = {
  socmedIcon: css`
    padding: 0.5rem;
  `,
};
