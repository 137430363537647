import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex } from '@topremit/shared-web/components/elements';
import { useMediaQuery } from '@topremit/shared-web/hooks';

import AddressMenu from './AddressMenu';
import AlsoByTopremitMenu from './AlsoByTopremitMenu';
import CompanyMenu from './CompanyMenu';
import ConnectWithUsMenu from './ConnectWIthUsMenu';
import ContactUsMenu from './ContactUsMenu';
import DownloadAppMenu from './DownloadAppMenu';
import LicensedByMenu from './LicensedByMenu';
import OperationalHoursMenu from './OperationalHoursMenu';
import ProductsMenu from './ProductsMenu';
import ResourcesMenu from './ResourcesMenu';

const menu = [
  <ContactUsMenu />,
  <OperationalHoursMenu />,
  <AddressMenu />,
  <AlsoByTopremitMenu />,
  <ProductsMenu />,
  <ResourcesMenu />,
  <CompanyMenu />,
  <DownloadAppMenu />,
  <ConnectWithUsMenu />,
  <LicensedByMenu />,
];

const menuOrder = {
  desktop: [
    [1, 2, 3, 4],
    [5, 6],
    [7, 8, 9, 10],
  ],
  tablet: [
    [5, 6, 7],
    [1, 2, 3, 8, 9, 10, 4],
  ],
  mobile: [[5, 6, 7, 1, 2, 3, 8, 9, 10, 4]],
};

export default function FooterBody() {
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletSm}px)`);
  const isTabletSize = useMediaQuery(`(max-width:${screenSize.desktopSm}px)`);

  const order = isMobileSize
    ? menuOrder.mobile
    : isTabletSize
      ? menuOrder.tablet
      : menuOrder.desktop;

  return (
    <Flex className={styled.root}>
      <Flex>
        {order.map((row, idxRow) => (
          <Flex key={idxRow} className={styled.row}>
            {row.map((col, idxCol) => (
              <Flex key={`${idxRow}-${idxCol}`} className={styled.col}>
                {menu[col - 1]}
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

const styled = {
  root: css`
    margin-bottom: 2.5rem;
    flex-direction: column;
    @media (max-width: ${screenSize.tabletSm}px) {
      flex-direction: column-reverse;
    }
  `,
  row: css`
    flex-direction: column;
  `,
  col: css`
    :not(:last-child) {
      margin-bottom: 2.5rem;
    }
    @media (max-width: ${screenSize.desktopSm}px) {
      margin-bottom: 2rem;
    }
  `,
};
