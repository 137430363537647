import { useGetFooterMenu } from '@hooks';
import { useTranslation } from '@topremit/shared-web/hooks';

import FooterMenuItem from './FooterMenuItem';
import FooterMenuList from './FooterMenuList';

export default function CompanyMenu() {
  const { t } = useTranslation();
  const { company } = useGetFooterMenu();

  return (
    <FooterMenuList title={t('footer.menu.menu_2_title')}>
      {company.map(({ icon, label, url, externalLink }, idx) => (
        <FooterMenuItem
          key={idx}
          leftIcon={icon}
          href={url}
          externalLink={externalLink}
        >
          {label}
        </FooterMenuItem>
      ))}
    </FooterMenuList>
  );
}
