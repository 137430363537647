import { css, cx } from '@emotion/css';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { EFoundUsSocialMedia } from '@topremit/shared-web/typings/profile.model';
import Link from 'next/link';
import { ReactNode } from 'react';

interface IFooterMenuItemProps {
  leftIcon?: ReactNode;
  href?: string;
  externalLink?: boolean;
  prefetch?: boolean;
  className?: string;
  children: ReactNode;
  analytic?: EFoundUsSocialMedia;
  onClick?: (name?: EFoundUsSocialMedia) => void;
}

export default function FooterMenuItem({
  leftIcon,
  href,
  externalLink,
  prefetch,
  className,
  children,
  analytic,
  onClick = () => {},
}: IFooterMenuItemProps) {
  const formattedLabel = () => {
    if (href)
      return (
        <Link
          href={href}
          {...(externalLink && { target: '_blank', rel: 'nofollow' })}
          {...(prefetch && { prefetch })}
          onClick={() => onClick(analytic)}
        >
          {children}
        </Link>
      );

    return <Text>{children}</Text>;
  };

  return (
    <Flex className={cx(styled.root, className)}>
      {leftIcon && (
        <Flex justify="center" align="center" className={styled.icon}>
          {leftIcon}
        </Flex>
      )}
      {formattedLabel()}
    </Flex>
  );
}

const styled = {
  root: css`
    width: unset;
    align-items: center;
  `,
  icon: css`
    padding-left: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  `,
};
